<template>
  <div class="w-100">
    <div
      class="d-flex justify-content-between align-items-center py-2"
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
    >
      <div class="heading font-butler color-black">Financials</div>
    </div>
    <div class="w-100 d-flex justify-content-between align-items-center mt-2">
        <div class="custom-card p-2">
            <div class="card-heading">
                Average Payout
            </div>
            <div class="custom-payments color-blue">
                ₹ {{financialData.payment_details.average_payout.toFixed(0)}}
            </div>
        </div>
        <div class="custom-card p-2">
            <div class="card-heading">
                Received payments
            </div>
            <div class="custom-payments color-blue">
                ₹ {{financialData.payment_details.received_payment}}
            </div>
        </div>
        <div class="custom-card p-2">
            <div class="card-heading">
                Due payments
            </div>
            <div class="custom-payments">
                ₹ {{financialData.payment_details.due_payments}}
            </div>
        </div>
    </div>
    <div class="w-100 py-2">
      <FinancialTable :tableData="financialData.full_data" :rowLength="financialData.rowLength" :paginate="useFinancials"/>
    </div>
  </div>
</template>
<script>
import FinancialTable from "./FinancialTable.vue";
import {financials} from "@/utils/api.js";
export default {
  data() {
    return {
      finData: financials(1),
      financialData: {
        payment_details: {
          average_payout: 5000,
          received_payment: 55000,
          due_payments: 7000,
        },
        full_data: [],
        rowLength: 50,
      },
    };
  },
  methods: {
    async useFinancials(j){
      const data = await financials(j);
      console.log(data);
      this.financialData.payment_details.average_payout = data.avg_payment;
      this.financialData.payment_details.received_payment = data.payment_done
      this.financialData.payment_details.due_payments = data.payment_due
      this.financialData.payment_details.due_payments = data.payment_due
      this.financialData.rowLength = data.count;
      this.financialData.full_data=[];
      for(let i = 0; i<data.data.length; i++){
        this.financialData.full_data.push({
        id : (j-1)*10 + i+1,
        name : data.data[i].name,
        email : (data.data[i].email?data.data[i].email:"N/A"),
        issue_date : data.data[i].issue_date,
        status : data.data[i].status,
        amount : data.data[i].payment,
        user_image : "https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
        })

      }
    }
  },
  mounted() {
    console.log(`At this point, vm.$el has been created and el has been replaced.`);
    this.useFinancials(1);
  },
  components: {
    FinancialTable,
  },
};
</script>
<style lang="scss" scoped>
.heading {
  font-size: 1.5rem;
  font-weight: bold;
}
.custom-card{
    width:30%;
    height: 6rem;
    background-color:  rgba(199, 199, 199, 0.05);
    .custom-payments{
        font-size: 1.5rem;
        font-weight: 700;
    }
}
</style>
