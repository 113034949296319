<template>
  <div class="vh-100 w-100 ">
    <div class="d-flex flex-column">
      <Navbar />
      <NavbarSm :openSideNavBar="openSideNavBar"/>
      <div class="d-flex w-100 h-100 justify-content-center align-items-start">
        <div class="custom-width-small custom-height-large p-2 pl-4 ">
          <Sidenavbar :setCurrentTab="setCurrentTab" :setReservationTab="setReservationTab" :setListingTab="setListingTab" :setAccountsTab="setAccountsTab"/>
        </div>
        <div class="custom-side-menu-sm custom-height-large p-2 pl-4 " v-if="sideNavbarSmall">
          <Sidenavbar :setCurrentTab="setCurrentTab" :setReservationTab="setReservationTab" :setListingTab="setListingTab" :setAccountsTab="setAccountsTab"/>
        </div>
        <div
          class="custom-width-large custom-height-large p-2 pr-4 overflow-auto"
        >
          <div v-if="currentTab == 1">
            <div class="mb-1"><OverviewOne /></div>
            <div class="mb-1">
              <OverviewThree
                :currentHostings="currentHostings"
                :currentHostingsLength="currentHostingsLength"
                :upcomingReservations="upcomingReservations"
                :upcomingReservationsLength="upcomingReservationsLength"
              />
            </div>
            <div class="mb-1"><OverviewTwo :graphInfo="graphInfo" :graphSum="graphSum"/></div>
          </div>
          <div v-if="currentTab == 2">
            <Listings :listLoading="listLoading" :items="filteredListingItems" :rows="listingRows" :handleListingPageChange="handleListingPageChange" />
          </div>
          <div v-if="currentTab == 3">
            <Reservations :items="filteredReservationItems" :rows="reservationRows" :handleReservationPageChange="handleReservationPageChange"  />
          </div>
          <div v-if="currentTab == 5">
            <Calendar />
          </div>
          <div v-if="currentTab == 6">
            <Accounts :currentAccountsTab="currentAccountsTab" :setPopupOpen="setPopupOpen"/>
          </div>
          <div v-if="currentTab == 7">
            <Financials />
          </div>
        </div>
        <div class="custom-popup-overlay" v-if="popupOpen !== 0">
          <div class="custom-change-password-popup card p-1" v-if="popupOpen === 1">
          
            <h2 class="font-butler text-center color-black mb-2">
              Change Password
            </h2>
            <div class="position-relative w-md-100 w-75 mx-auto mb-2">
              <label for="">Old Password</label>
              <input type="password" class="form-control" placeholder="Old Password" v-model="old_password">
              <router-link to="/" class="color-blue font-small">Forgot Password?</router-link>
            </div>
            
            <div class="position-relative w-md-100 w-75 mx-auto mb-2">
              <label for="">New Password</label>
              <input type="password" class="form-control" placeholder="New Password" v-model="new_password">
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn bg-color-white color-blue mr-2" style="border:1px solid rgb(13, 110, 253);" @click="setPopupOpen(0)">Cancel</button>
                <button class="btn bg-color-blue color-white" @click="useChangePassword">Confirm</button>
            </div>
          </div>
          <div class="custom-send-verification-code-popup card p-1" v-if="popupOpen === 2">
            <h2 class="font-butler text-center color-black mb-2">
              Change Account Details
            </h2>
            <p class="text-center font-small">To protect your privacy, you must verify your identity before changing your account details.</p>
            <button class="btn w-75 rounded color-white bg-color-blue cursor-pointer mx-auto mb-2" @click="setPopupOpen(3)">SEND VERIFICATION CODE</button>
            <p class="font-small mx-auto">A  6 digits verification code will be send to your saved email address.</p>
          </div>
          <div class="custom-send-verification-code-popup-2 card p-1" v-if="popupOpen === 3">
            <h2 class="font-butler text-center color-black mb-2">
              Change Account Details
            </h2>
            <p class="font-small text-center">
              Enter the verification code sent to your email address and click confirm.
            </p>
            <div class="position-relative w-md-100 w-75 mx-auto">
              <input type="password" class="form-control text-center" placeholder="Verification Code">
            </div>
            <p class="font-small text-center color-blue cursor-pointer mb-1">
              Resend code in 30 seconds
            </p>
            <div class="d-flex justify-content-center">
                <button class="btn bg-color-white color-blue mr-2" style="border:1px solid rgb(13, 110, 253);" @click="setPopupOpen(0)">Cancel</button>
                <button class="btn bg-color-blue color-white">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/views/newcomponents/OwnerNavbar/OwnerNavbar.vue";
import NavbarSm from "@/views/newcomponents/NavbarSm/NavbarSm.vue";
import Sidenavbar from "@/views/newcomponents/SideNavbar/SideNavbar.vue";
import Listings from "@/views/newcomponents/DashboardItems/Listings.vue";
import Reservations from "@/views/newcomponents/DashboardItems/Reservations.vue";
import OverviewOne from "@/views/newcomponents/DashboardItems/OverviewOne.vue";
import OverviewThree from "@/views/newcomponents/DashboardItems/OverviewThree.vue";
import OverviewTwo from "@/views/newcomponents/DashboardItems/OverviewTwo.vue";
import Calendar from "@/views/newcomponents/DashboardItems/Calendar/Calendar.vue";
import Accounts from "@/views/newcomponents/Accounts/Accounts.vue";
import Financials from "@/views/newcomponents/Financials/Financials.vue";
import { getOwnerListingView, getOwnerReservations, latestReservations, balanceGraphApi, changePassword } from "@/utils/api.js";
export default {
  data() {
    return {
      currentTab: 2,
      listingItems: [],
      filteredListingItems : [],
      reservationItems: [],
      filteredReservationItems : [],
      reservationRows: 0,
      listingRows: 0,
      sideNavbarSmall: false,
      currentHostings: [],
      currentHostingsLength: 0,
      upcomingReservations: [],
      upcomingReservationsLength: 0,
      graphInfo : [0,0,0,0,0,0,0,0,0,0,0,0],
      graphSum : 0,
      //Sub tab variables for reservation and lisiting
      currentListingTab : 1,
      currentReservationTab : 1,
      currentAccountsTab : 1,
      popupOpen:0,
      reservationPageNo: 1,
      listingPageNo: 1,
      listLoading:true,
      
  	  old_password:"",
      new_password:"",
      errors:{
        pwdErr: false,
      }

    };
  },
  methods: {
    setCurrentTab(val) {
      this.currentTab = val;
      console.log("Value changed to:", this.currentTab);
    },

    validatePwd() {
      if(this.new_password.length<8 || this.new_password==null || this.new_password == undefined){
        this.$toasted.error(
            "Please enter a valid password of minimum 8 chars.",
            {
              position: "top-center",
              action: {
                text: "Cancel",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            }
          );
          return false;
      }
      return true
    },


    // change password

    async useChangePassword() {
      if(!this.validatePwd()){
        return;
      }
      try{
        
        const data = {old_password: this.old_password, new_password: this.new_password}
        console.log(data);
        const resData = await changePassword(data);
        console.log(resData);
        this.setPopupOpen(0);
        this.errors.pwdErr = false;
      }
      catch(err){
        console.log(err);
        this.errors.pwdErr = true;
        this.$toasted.error(
            "Incorrect Password",
            {
              position: "top-center",
              action: {
                text: "Cancel",
                onClick: (e, toastObject) => {
                  toastObject.goAway(0);
                },
              },
            }
          );
      }
    },


    //using the listing view details api

    async useGetOwnerListingView() {
      try {
        const data = await getOwnerListingView(this.listingPageNo);

        console.log("Yeh hai data", data.results);
        this.listingItems = data.results;
        this.listingRows = data.count;
        this.filteredListingItems = data.results;
        this.listLoading = false;
      } catch (err) {
        console.log(err);
        this.listLoading = false;
      }
    },

    handleReservationPageChange(val) {
      this.reservationPageNo = val;
      this.useGetReservations();
    },

    handleListingPageChange(val) {
      this.listingPageNo = val;
      this.useGetOwnerListingView();
    },

    //using the reservation api

    async useGetReservations(){
      try{
        const data = await getOwnerReservations(this.reservationPageNo);
        console.log('Yeh hai reservation data', data);
        this.reservationItems = data.data;
        console.log(this.reservationItems)
        this.reservationRows = data.count;
        this.filteredReservationItems = data.data;
      }
      catch(err){
        console.log(err);
      }
    },


    //using the current hosting and upcoming reservations api
    async useLatestReservations(){

        const data = await latestReservations();
        if(data.success){
            console.log('This is the data%%%%:',data);
            this.currentHostings = data.currently_hosting;
            this.currentHostingsLength = this.currentHostings.length;
            this.upcomingReservations = data.upcoming;
            this.upcomingReservationsLength = this.upcomingReservations.length;
            console.log('Current Hostings length:', this.currentHostings, "and upcoming reservation:", this.upcomingReservations);
        }
    },


    //using the balance graph info api
    async getBalanceGraphApi(){
      const data = await balanceGraphApi();
      if(data.success){
        const graphData = data.data;
        graphData.map((item) => {
          this.graphInfo[item.month - 1] = item.earnings;
          this.graphSum += item.earnings;
        })

        console.log('This is earnings tab:',this.graphInfo);

      }

    },

    setPopupOpen(val){
      this.popupOpen = val;
      this.errors.pwdErr = false;
      console.log("Value changed to pop:", this.popupOpen);
    },

    setReservationTab(val){
      this.currentReservationTab = val;
    },

    setListingTab(val){
      this.currentListingTab = val;
    },

    setAccountsTab(val){
      this.currentAccountsTab = val;
    },

    //Filter listing view results

    filterLisitingView(){
      if(this.currentListingTab == 1){
        this.filteredListingItems = this.listingItems
        this.listingRows = this.filteredListingItems.length

      }
      else if(this.currentListingTab == 2){

        this.filteredListingItems = this.listingItems.filter((item) => {
          if(item.listing_status == "unlisted"){
            return item;
          }
        })

        this.listingRows = this.filteredListingItems.length


      }
      else if(this.currentListingTab == 3){

        this.filteredListingItems = this.listingItems.filter((item) => {
          if(item.listing_status == "listed"){
            return item;
          }
        })

        this.listingRows = this.filteredListingItems.length


      }
      else{
        this.filteredListingItems = this.listingItems.filter((item) => {
          if(item.listing_status == "active"){
            return item;
          }
        })

        this.listingRows = this.filteredListingItems.length
        
      }
    },


    //Filter reservation view results
    filterReservationView(){
      if(this.currentReservationTab == 1){
        this.filteredReservationItems = this.reservationItems;
        this.reservationRows = this.filteredReservationItems.length;

      }
      else if(this.currentReservationTab == 2){
        this.filteredReservationItems = this.reservationItems.filter((item) => {
          if(item.status == "currently_hosting"){
            return item;
          }
        })
        this.reservationRows = this.filteredReservationItems.length;
        

      }
      else if(this.currentReservationTab == 3){

         this.filteredReservationItems = this.reservationItems.filter((item) => {
          if(item.status == "upcoming"){
            return item;
          }
        })
        this.reservationRows = this.filteredReservationItems.length;

      }
      else if(this.currentReservationTab == 4){
         this.filteredReservationItems = this.reservationItems.filter((item) => {
          if(item.status == "completed"){
            console.log(item.status)
            return item;
          }
        })
        this.reservationRows = this.filteredReservationItems.length;

      }
      else{
         this.filteredReservationItems = this.reservationItems.filter((item) => {
          
          if(item.status == "cancelled"){
            console.log(item.status)
            return item;
          }
        })
        
        this.reservationRows = this.filteredReservationItems.length;

      }

    },


    openSideNavBar(){
      this.sideNavbarSmall = !this.sideNavbarSmall;
    }



  },

  mounted() {
    this.useGetOwnerListingView();
    this.useGetReservations();
    this.useLatestReservations();
    this.getBalanceGraphApi();
  },
  watch:{
    currentReservationTab : function(){
      console.log('Reservation Tab changed to :', this.currentReservationTab);
      this.filterReservationView();
      
    },
    currentListingTab : function(){
      console.log('Listing Tab changed to :', this.currentListingTab);
      this.filterLisitingView();
      
    }
  },

  components: {
    Navbar,
    Sidenavbar,
    Listings,
    Reservations,
    OverviewOne,
    OverviewTwo,
    OverviewThree,
    Calendar,
    NavbarSm,
    Accounts,
    Financials
  },
};
</script>
<style lang="scss" scoped>
.custom-width-small {
  width: 20%;
}
.custom-width-large {
  width: 80%;
}
.custom-height-large {
  height: 90vh;
}
.custom-popup-overlay{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.12);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width:100%;
  overflow: hidden;
  z-index: 10;
}

.custom-change-password-popup{
  width: 836px;
  height: 306px;
  max-width: 95%;
}
.custom-send-verification-code-popup{
  width: 598px;
  height: 224px;
  max-width: 95%;
}
.custom-send-verification-code-popup-2{
  width: 598px;
  height: 224px;
  max-width: 95%;
}
@media only screen and (max-width: 600px) {
  .custom-width-small {
    display: none !important;
  }
  .custom-width-large {
    width: 100%;
    padding-right: 0;
    padding: 1rem !important;
  }
  .custom-side-menu-sm{
    position: fixed;
    left: 0%;
    background-color: #fff;
    z-index: 10000;
    box-shadow: 5px 20px 30px 0px rgba(0,0,0,0.12);
  }
}
</style>
