<template>
    <div>
       <nav
      class="d-flex d-sm-none justify-content-between align-items-center px-1 py-2 shadow"
    >
    <div class="custom-logo"><MyLogo /></div>
    <div class="custom-menu d-flex align-items-center">
        <div style="margin-top:5px;">
            <router-link
              to="/pages/listingpage"
              class="custom-router-link d-flex justify-content-between align-items-center "
            >
              <img :src="PlusIcon" style="height:1.5rem;width:auto; cursor:pointer;" alt="" />
              <span>Add listing</span>
            </router-link>
          </div>
        <div class="position-relative ml-2">
            <img :src="Menu" alt="Menu" style="height:2rem;width:auto; cursor:pointer;" @click="openSideNavBar" />
        </div>
        <div class="position-relative ml-2">
            <img :src="Logout" alt="Logout" style="height:2rem;width:auto; cursor:pointer;" @click="logOut" />
        </div>
    </div>
    </nav> 
    </div>
</template>
<script>
import Logout from "@/assets/icons/log_out.png";
import Menu from "@/assets/icons/menu.png";
import PlusIcon from "@/assets/icons/Plus.png";
import MyLogo from '@/views/newcomponents/Logo/Logo.vue';
export default {
    data() {
        return {
            Logout,
            Menu,
            PlusIcon
        };
    },
    props:["openSideNavBar"],
    components:{
      MyLogo
    }
}
</script>
<style lang="scss" scoped>
    .custom-menu{
        font-size: 3rem;
        z-index: 100;
    }
    .custom-router-link {
    padding: 0.3rem 1rem;
    border: 2px solid rgba($color: #000000, $alpha: 0.4);
    border-radius: 10rem;
    font-size: 12px;
    span {
      margin-left: 0.5rem;
      color: rgba($color: #000000, $alpha: 0.55);
      font-weight: bold;
    }
  }
</style>