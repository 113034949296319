<template>
  <div class="custom-wrap w-100 d-flex ">
    <div class="custom-width w-50 bg-color-white shadow rounded mr-1/2">
      <div class="custom-box p-1">
        <div
          class="font-mid-large color-black mb-2"
          v-if="currentHostingsLength > 0"
        >
          {{ currentHostingsLength }} Currently Hosting
        </div>
        <div
          class="font-mid-large color-black"
          v-if="currentHostingsLength <= 0"
        >
          No Current Hostings
        </div>
        <div class="w-100 d-block">
          <div
            class="d-flex justify-content-start mb-1 align-items-center"
            v-for="(item, index) in currentHostings"
            :key="index"
          >
            <div>{{ item.Guest_name }} will be checking out on {{ item.end.slice(0,11) }}</div>
            <div class="custom-badge">
              <img :src="storeIcon" alt="" />
              {{ item.house_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-width w-50 bg-color-white shadow rounded ml-1">
      <div class="custom-box p-1">
        <div
          class="font-mid-large color-black mb-2"
          v-if="upcomingReservationsLength > 0"
        >
          {{ upcomingReservationsLength }} Upcoming Reservations
        </div>
        <div
          class="font-mid-large color-black"
          v-if="upcomingReservationsLength <= 0"
        >
          No Upcoming Reservations
        </div>
        <div class="w-100 d-block">
          <div
            class="d-flex justify-content-start mb-1 align-items-center"
            v-for="(item, index) in upcomingReservations"
            :key="index"
          >
            <div>{{ item.Guest_name }} will be checking in on {{ item.start.slice(0,11) }}</div>
            <div class="custom-badge green">
              <img :src="storeIcon" alt="" />
              {{ item.house_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storeIcon from "@/assets/icons/store.png";
export default {
  data() {
    return {
      storeIcon,
    };
  },
  props: [
    "currentHostings",
    "currentHostingsLength",
    "upcomingReservations",
    "upcomingReservationsLength",
  ],
};
</script>

<style lang="scss" scoped>
.custom-box {
  min-height: 13rem;
  max-height: 45rem;
  overflow: hidden;
}

.custom-badge {
  padding: 0.3rem 1rem;
  border-radius: 10rem;
  background-color: rgba(1, 1, 1, 0.05);
  margin-left: 1rem;
  img {
    height: 1.5rem;
    width: auto;
  }
  &.green {
    background-color: #d4f4e2;
  }
}
@media only screen and (max-width: 600px) {
  .custom-wrap {
    flex-wrap: wrap !important;
  }
  .custom-width {
    width: 100% !important;
    margin-right: 0rem !important;
    margin-left: 0rem !important;
    &:nth-child(1) {
      margin-bottom: 1rem;
    }
  }
}
</style>
