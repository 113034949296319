<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Balance
        </b-card-title>
        <b-card-sub-title>Commercial networks & enterprises</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1">
          $ {{graphSum}}
        </h5>
        <b-badge variant="light-secondary">
          <feather-icon
            icon="ArrowDownIcon"
            size="16"
            class="text-danger mr-25"
          />
          <span class="align-middle">20%</span>
        </b-badge>
      </div>
      <!--/ badge -->
    </b-card-header>

    <b-card-body style="overflow-x:scroll; padding-right:1rem;">
      <vue-apex-charts
        type="line"
        height="400"
        :options="lineChartSimple.chartOptions"
        :series="lineChartSimple.series"
        style="min-width:500px;"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import apexChatData from "./apexChartData";

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      apexChatData,
      lineChartSimple: {
        series: [
          {
            data: this.graphInfo,
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 10,
            strokeOpacity: 0.5,
            strokeColors: ["#7367f0"],
            colors: ["#7367f0"],
          },
          colors: ["#7367f0"],
          dataLabels: {
            enabled: true,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                data.series[data.seriesIndex][data.dataPointIndex]
              }Rs</span></div>`;
            },
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sept",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      },
    };
  },
  props :[
    "graphInfo",
    "graphSum"
  ]


};
</script>
