<template>
  <div class="w-100 shadow p-1 bg-color-white rounded">
    <div class="font-mid-large color-black pb-1">
      Setup bank account details for payouts
    </div>
    <div class="font-mid-small pb-1">
      Add your bank account as a payout method so you can receive your rent
      payments.
    </div>
    <div class="btn bg-color-blue color-white">
      Setup payments
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "Mahipal",
    };
  },
};
</script>

<style lang="scss" scoped></style>
