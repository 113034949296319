<template>
  <div class="w-100 shadow p-1 bg-color-white rounded">
    <div class="font-mid-large pb-1 ">All Reservations</div>
    <b-table
      responsive
      id="my-table"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      :head-variant="headVariantL"
    >
      <template #cell(check_in)="data">
        <div class="d-flex flex-column">
          <div class="font-regular color-black font-weight-500 pb-1">
            {{ data.value.date }}
          </div>
          <div class="font-small pb-1">
            {{ data.value.time }}
          </div>
        </div>
      </template>
      <template #cell(check_out)="data">
        <div class="d-flex flex-column">
          <div class="font-regular color-black font-weight-500 pb-1">
            {{ data.value.date }}
          </div>
          <div class="font-small pb-1">
            {{ data.value.time }}
          </div>
        </div>
      </template>
    </b-table>
    <br />
    <div class="d-flex justify-content-between ">
      <div class="custom-showing">
        Showing {{ calculatePages(items,perPage,currentPage) }} items out of {{ rows }}
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { BPagination, BBadge } from "bootstrap-vue";
import Pagination from "@/views/components/pagination/PaginationSeparated.vue";
// import Pagination from "@/views/newcomponents/Pagination/Pagination.vue";
export default {
  data() {
    return {
      fields: [
        {
          key: "house_id",
          label: "HOUSE ID",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
        {
          key: "check_in",
          label: "CHECK IN",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
        {
          key: "check_out",
          label: "CHECK OUT",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
        // {
        //   key: "confirmation_code",
        //   label: "CONFIRMATION CODE",
        //   tdClass: "",
        //   thClass: "custom-background color-blue font-weight-500",
        // },
        {
          key: "address",
          label: "ADDRESS",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
        {
          key: "guest",
          label: "GUEST",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
      ],

      headVariant: "dark",
      headVariantL: "light",
      currentPage: 1,
      perPage: 5,
    };
  },
  methods:{
    calculatePages(items, perPage, currentPage) {
      const totalPages = Math.ceil(items.length / perPage);
      if(items.length === 0){
        return 0;
      }
      if(currentPage >= totalPages) {
        return items.length - perPage * (totalPages - 1);
      }
      else{
        return perPage;
      }
    },
  },
  components: {
    BTable,
    Pagination,
    BPagination,
    BBadge,
  },
  props: ["items", "rows", "handleReservationPageChange"],
  watch: {
    currentPage() {
      console.log("currentPage", this.currentPage);
      this.handleReservationPageChange(this.currentPage);
    },
  },
};
</script>

<style lang="scss" scoped>
#my-table {
  th {
    color: blueviolet;
  }
}
@media only screen and(max-width:600px) {
  .custom-showing {
    display: none !important;
  }
}
</style>
