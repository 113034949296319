<template>
  <div class="w-100">
    <nav
      class="d-none d-sm-flex  justify-content-between align-items-center px-4 py-2 shadow"
    >
      <div style="height:3rem;"><MyLogo /></div>

      <div class="custom-container d-flex justify-content-between">
        <div
          class="custom-search w-50 d-flex justify-content-center align-items-center px-2 border-right"
        >
          <div>
            <img :src="Search" alt="" />
          </div>
          <input type="text" placeholder="Search units or leases...." />
        </div>
        <div
          class="custom-details w-50 d-flex justify-content-end align-items-center px-2"
        >
          <div>
            <router-link
              to="/pages/listingpage"
              class="custom-router-link d-flex justify-content-between align-items-center"
            >
              <img :src="PlusIcon" alt="" />
              <span>Add listing</span>
            </router-link>
          </div>

          <div class="position-relative mx-2">
            <img :src="Logout" alt="Logout" style="height:2rem;width:auto; cursor:pointer;" @click="logOut" />
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import PlusIcon from "@/assets/icons/Plus.png";
import Logout from "@/assets/icons/log_out.png";
import Bell from "@/assets/icons/bell.png";
import Search from "@/assets/icons/loupe.png";
import { getUserDetails } from "@/utils/api.js";
import MyLogo from '@/views/newcomponents/Logo/Logo.vue';
export default {
  data() {
    return {
      PlusIcon,
      Logout,
      Bell,
      Search,
      number: 9,
      name: "Mahipal Balan",
      userPicture:
        "https://www.adobe.com/content/dam/cc/us/en/creativecloud/photography/discover/portrait-photography/CODERED_B1_portrait_photography-P4a_438x447.jpg.img.jpg",
    };
  },

  methods: {
    async useGetUserDetails() {
      try {
        const user = await getUserDetails();
        if (user.success) {
          this.name = user.name ? user.name : user.email;
        }
      } catch (err) {
        console.log(err);
      }
    },
    logOut() {
      localStorage.removeItem("app_access_token");
      localStorage.removeItem("app_refresh_token");
      this.$router.push({path:"/pages/newlogin"});
    },
  },

  mounted() {
    this.useGetUserDetails();
  },
  components:{
    MyLogo
  }
};
</script>
<style lang="scss" scoped>
nav {
  display: flex;
  .custom-container {
    width: 60%;
  }
  .custom-details {
    img {
      height: 1.2rem;
      width: auto;
    }
    .custom-notification {
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 50%;
      top: -0.3rem;
      right: -0.5rem;
    }
    .custom-image {
      img {
        height: 2.2rem;
        width: 2.2rem;
        object-fit: cover;
        border-radius: 2rem;
      }
    }
  }
  .custom-search {
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba(1, 1, 1, 0.2);
      outline: none;
      margin-left: 0.4rem;
      &::placeholder {
        opacity: 0.3;
        font-weight: 600;
        color: #111;
      }
    }
    img {
      height: 1.2rem;
      width: 1.2rem;
      opacity: 0.3;
    }
  }
  .custom-router-link {
    padding: 0.3rem 1rem;
    border: 2px solid rgba($color: #000000, $alpha: 0.4);
    border-radius: 10rem;
    span {
      margin-left: 0.5rem;
      color: rgba($color: #000000, $alpha: 0.55);
      font-weight: bold;
    }
  }
}
</style>
