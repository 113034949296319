<template>
  <div class="w-100">
    <b>All Activity</b>
    <b-table
      responsive
      id="my-table"
      :fields="fields"
      :items="tableData"
      :per-page="perPage"
      :current-page="1"
      :head-variant="headVariantL"
    >
    <template #cell(status)="data">
        <b-badge pill variant="light-success" style="text-transform:capitalize;" v-if="data.item.status === 'paid'">
          {{ data.item.status }}
        </b-badge>
        <b-badge pill variant="light-danger" style="text-transform:capitalize;" v-if="data.item.status === 'cancelled'">
          {{ data.item.status }}
        </b-badge>
        <b-badge pill variant="light-dark" style="text-transform:capitalize;" v-if="data.item.status === 'due'">
          {{ data.item.status }}
        </b-badge>
        <b-badge pill variant="light-warning" style="text-transform:capitalize;" v-if="data.item.status === 'refunded'">
          {{ data.item.status }}
        </b-badge>
    </template>
    <template #cell(amount)="data">
        <div>
            ₹ {{ data.item.amount }}
        </div>
    </template>
    <template #cell(name)="data">
        <div class="d-flex align-items-center">
            <img :src="data.item.user_image" class="mr-1" alt="" style="height:2.4rem;width:2.4rem;border-radius:50%;">
            <div>
                <div class="font-mid-small"><strong>{{ data.item.name }}</strong></div>
                <div class="font-small">{{ data.item.email }}</div>
            </div>
        </div>
    </template>
    </b-table>
    <div class="d-flex justify-content-end ">
      <b-pagination
        v-model="currentPage"
        :total-rows="rowLength"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import { BTable } from "bootstrap-vue";
import { BPagination, BBadge } from "bootstrap-vue";
import Pagination from "@/views/components/pagination/PaginationSeparated.vue";
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "#",
          tdClass: "",
          thClass: "font-weight-500",
        },
        {
          key: "name",
          label: "NAME",
          tdClass: "",
          thClass: "font-weight-500",
          sortable: true

        },
        {
          key: "amount",
          label: "PAYMENT",
          tdClass: "",
          thClass: "font-weight-500",
          sortable: true
        },
        {
          key: "issue_date",
          label: "ISSUED DATE",
          tdClass: "",
          thClass: "font-weight-500",
          sortable: true
        },
                {
          key: "status",
          label: "STATUS",
          tdClass: "",
          thClass: "font-weight-500",
        },
      ],
      headVariant: "dark",
      headVariantL: "light",
      currentPage: 1,
      perPage: 10,
    };
  },

  watch:{
    currentPage(){
      console.log(`page is changing to ${this.currentPage}`);
      this.paginate(this.currentPage);
    }
  },
  components: {
    BTable,
    BPagination,
    BBadge,
    Pagination,
  },
  props:["tableData", "rowLength", "paginate"]
};
</script>
<style lang="scss" scoped></style>
