<template>
<div>
  <div class="w-100 shadow p-1 bg-color-white rounded" v-if="!listLoading && items && items.length > 0">
    <div class="font-mid-large pb-1">All Listings</div>
    <b-table responsive id="my-table" :fields="fields" :items="items" >
      <template #cell(occupancy)="data">
        <b-badge pill :variant="data.value.occupancy_status === 'vacant' ? 'badge-secondary' : 'badge-success'">
          {{ data.value.occupancy_status }}
        </b-badge>
      </template>
      <template #cell(listing_status)="data">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <button class="btn btn-primary" @click="pushToEdit(data)">
            Edit
          </button>
          
        </div>
      </template>
      <template #cell(market_variant)="data">
        <b-form-checkbox
          v-bind:checked="data.item.listing_status=='listed'?true:false"
          class="custom-control-primary"
          name="check-button"
          @change="useToggleListing(data)"
          switch
        />
      </template>
    </b-table>
    <br />
    <div class="d-flex justify-content-between">
      <div class="custom-showing">
        Showing {{ calculatePages(items, perPage, currentPage) }} items out of
        {{ rows }}
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      ></b-pagination>
    </div>
  </div>
  <div style="height:80vh;width:100%;display:flex;justify-content:center;align-items:center;" v-if="!listLoading && items && items.length === 0">
    <a href="/pages/listingpage" style="height:6rem; cursor:pointer; font-size:1.4rem; font-weight:bold; width:20rem; border-radius:10rem; color:blueviolet; border: 2px solid blueviolet; display:flex;justify-content:center;align-items:center;">
      Add listings
    </a>
  </div>
</div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { BPagination, BBadge } from "bootstrap-vue";
import {toggleListing} from "@/utils/api"
import Pagination from "@/views/components/pagination/PaginationSeparated.vue";

// import Pagination from "@/views/newcomponents/Pagination/Pagination.vue";
export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "ID",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
        {
          key: "address_line_1",
          label: "ADDRESS",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
        {
          key: "unit",
          label: "UNIT",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
        {
          key: "occupancy_status",
          label: "OCCUPANCY",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
        {
          key: "listing_status",
          label: "Actions",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
        {
          key: "market_variant",
          label: "Active/Inactive",
          tdClass: "",
          thClass: "custom-background color-blue font-weight-500",
        },
      ],

      headVariant: "dark",
      headVariantL: "light",
      currentPage: 1,
      perPage: 5,
    };
  },

  methods: {
    async useToggleListing(data){
      console.log("%%%", data.item);
      let res = await toggleListing({house_id: data.item.id})
    },
    pushToEdit(data) {
      console.log("Reaching here:", data);
      this.$router.push(`/pages/editingpage/${data.item.id}`);
    },
    calculatePages(items, perPage, currentPage) {
      const totalPages = Math.ceil(items.length / perPage);
      if (items.length === 0) {
        return 0;
      }
      if (currentPage >= totalPages) {
        return items.length - perPage * (totalPages - 1);
      } else {
        return perPage;
      }
    },
  },

  watch: {
    items: function () {
      console.log("Inside watch function!", this.items);
    },
    currentPage() {
      console.log("currentPage", this.currentPage);
      this.handleListingPageChange(this.currentPage);
    },
  },

  components: {
    BTable,
    Pagination,
    BPagination,
    BBadge,
  },
  props: ["items", "rows", "handleListingPageChange", "listLoading"],
};
</script>

<style lang="scss" scoped>
#my-table {
  th {
    color: blueviolet;
  }
}
@media only screen and(max-width:600px) {
  .custom-showing {
    display: none !important;
  }
}
</style>
