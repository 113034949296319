<template>
  <div class="p-1 d-flex flex-column">
    <div class="w-100 position-relative mb-2">
      <input
        type="text"
        placeholder="Search property"
        class="rounded w-100"
        v-model="property"
      />

      <img :src="Loupe" alt="Search Icon" class="custom-search" />
    </div>
    <div class="w-100">
      <div
        class="custom-property px-2 py-1 color-black mb-1 border-bottom"
        :class="{ active: index === 0 }"
        v-for="(item, index) in (tempProperties.length != 0 ? tempProperties : allProperties)"
        :key="index"
        @click="setPropertyActive($event, item.id)"
      >
        {{ item.name }}
      </div>
    </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      ></b-pagination>
  </div>
</template>

<script>
import Loupe from "@/assets/icons/loupe.png";
import { getOwnerListingView} from "@/utils/api.js";
import { BPagination } from "bootstrap-vue";



export default {
  data() {
    return {
      Loupe,
      allProperties: [
        
      ],

      property: '',
      tempProperties:[],
      watcherProperty : '',
      totalRows:0,
      perPage: 5,
      currentPage: 1,
    };
  },
  methods: {
    setPropertyActive(e, property) {
      e.preventDefault();
      let customProperties = document.querySelectorAll(".custom-property");
      for (let index = 0; index < customProperties.length; index++) {
        customProperties[index].classList.remove("active");
      }
      e.target.classList.add("active");
      
      this.setCalendarProperty(property);
      
    },
    async useGetOwnerListingView(page) {
      
        const data = await getOwnerListingView(page);

          console.log("Inside SideBar!", data);
        const extendedDetails = data.results;
        this.totalRows = data.count;
        this.allProperties = [];
        extendedDetails.forEach((item,index) => {
          const property = {
            id : item.id,
            name : item.house_name,
            address : item.address_line_1
          }
          this.allProperties.push(property);
        }) 
        this.setCalendarProperty(this.allProperties[0].id);
     
      
    },
  },
  components: {
    BPagination,
  },

  watch : {
    property :  function(){
      console.log(this.property, "Changed")
      const smallCaseProperty = this.property.toLowerCase();
      this.tempProperties =  this.allProperties.filter((prop) => {
        if(prop.name.toLowerCase().includes(smallCaseProperty)){
          return prop;
        }
      })
    },
    currentPage() {
      console.log("currentPage", this.currentPage);
      this.useGetOwnerListingView(this.currentPage);
    },

    watcherProperty : function(){
      console.log(this.watcherProperty, "Wtacher property has changed!");
      
    }

  },
  mounted(){
    this.useGetOwnerListingView(this.currentPage);
  },

  props:
    ["setCalendarProperty"]

};
</script>

<style lang="scss" scoped>
input {
  outline: none;
  border: 1px solid rgba($color: #000000, $alpha: 0.2);
  padding: 0.5rem 0.5rem;
  padding-right: 2rem;
  transition: 0.3s ease;
  &:focus,
  &:active {
    border: 1px solid rgba($color: rgb(13, 110, 253), $alpha: 0.7);
    box-shadow: 0px 4px 10px -1px rgba($color: rgb(13, 110, 253), $alpha: 0.25);
  }
}
.custom-search {
  cursor: pointer;
  height: 1.5rem;
  position: absolute;
  width: auto;
  right: 0.3rem;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0.3;
}
.custom-property {
  cursor: pointer;
  &.active {
    background-color: #e7f0ff;
    color: rgb(13, 110, 253) !important;
    border-bottom: none;
    border-radius: 1rem;
  }
}
</style>
