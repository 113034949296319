<template>
  <div>
    <div class="card w-75 p-2" v-show="currentAccountsTab === 1">
      <h2 class="font-butler color-black mb-2">Personal Details</h2>
      <!-- <div class="custom-image-container d-flex align-items-center">
        <img
          src="https://www.adobe.com/express/create/media_127540366421d3d5bfcaf8202527ca7d37741fd5d.jpeg?width=2000&format=webply&optimize=medium"
          alt=""
        />
        <div class="ml-4">
          <div class="d-flex mb-2">
            <button class="btn btn-warning">Upload</button>
          </div>
          <div>Allowed JPG, GIF or PNG. Max size of 800kB</div>
        </div>
      </div> -->
      <div class="custom-user-container d-flex mt-2 w-100">
        <div class="mr-2 w-50">
          <label for="">Name</label>
          <input type="text" class="form-control" placeholder="Name" :value="name" readOnly/>
        </div>
        <div class="w-50">
          <label for="">Email</label>
          <input type="email" class="form-control" placeholder="Email" :value="email" readOnly/>
        </div>
      </div>

      <h2 class="font-butler color-black my-2">Security Details</h2>
      <div class="custom-user-container d-flex w-100">
        <div class="w-50">
          <label for="">Password</label>
          <input type="password" class="form-control" placeholder="Password" value="********" readOnly/>
          <div class="font-small color-blue cursor-pointer" @click="useSetPopupOpen(1)">Change</div>
        </div>
      </div>
    </div>
    <div class="card w-75 p-2" v-show="currentAccountsTab === 2">
      <div class="mb-3 d-flex justify-content-between">
        <div>
          <h2 class="font-butler color-black mb-1">Account Details</h2>
          <p>Your saved account details.</p>
        </div>
        <!-- <div
          class="position-relative d-flex align-items-center color-blue cursor-pointer"
          @click="useSetPopupOpen(2)"
        >
          <img
            :src="pencilIcon"
            height="13"
            style="margin-right: 5px"
            alt="No Image Available"
          />
          Edit
        </div> -->
      </div>
      <div class="custom-user-container d-flex w-100">
        <div class="mr-2 mb-2 w-50">
          <label for="">Account holder's name</label>
          <input type="text" class="form-control" placeholder="Account holder's name" v-model="account_holder_name" :readonly="isDisabled"/>
        </div>
        <div class="w-50 mb-2">
          <label for="">Account Number</label>
          <input
            type="text"
            class="form-control"
            placeholder="Account Number"
            v-model="account_number"
            :readonly="isDisabled"
          />
        </div>
      </div>
      <div class="custom-user-container d-flex w-100">
        <div class="mr-2 mb-2 w-50">
          <label for="">Branch Name</label>
          <input type="text" class="form-control" placeholder="Branch Name" v-model="branch_name" :readonly="isDisabled"/>
        </div>
        <div class="w-50 mb-2">
          <label for="">IFSC code</label>
          <input
            type="text"
            class="form-control"
            placeholder="IFSC Code"
            v-model="ifsc_code"
            :readonly="isDisabled"
          />
        </div>
      </div>
      <div @click="submitDetails" v-if="!isDisabled" style="padding:5px 10px; border-radius:4px; color:#fff; background:navy; font-size:12px;text-align:center; width:100px; ">
        Submit
      </div>
    </div>
    <div class="card w-75 p-2" v-show="currentAccountsTab === 3">
      <div class="mb-3 d-flex justify-content-between">
        <div>
          <h2 class="font-butler color-black mb-1">Documents Submitted</h2>
          <p>All submitted documents details.</p>
        </div>
      </div>
      <div class="custom-document-not-submitted">
        <div class="w-100 p-1 shadow" style="color: #ff9f43; font-weight: bold">
          This feature is coming soon.
        </div>
        <!-- <div class="w-100 p-1" style="color: #ff9f43">Verify here.</div> -->
      </div>
      <div class="custom-document-submitted">
        <!-- <div class="custom-user-container d-flex mt-3 w-100">
          <div class="mr-2 w-50">
            <label for="">Aadhar Card</label>
            <input type="text" class="form-control" placeholder="Aadhar Card" />
          </div>
          <div class="w-50">
            <label for="">Pan Card</label>
            <input type="text" class="form-control" placeholder="Name" />
          </div>
        </div>
        <div class="custom-user-container d-flex mt-2 w-100">
          <div class="mr-2 w-50">
            <label for="">GST Details</label>
            <input type="text" class="form-control" placeholder="Aadhar Card" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import PencilIcon from "@/assets/icons/pencil.png";
import { getUserDetails, getOwnerAccountDetails, postOwnerAccountDetails } from "@/utils/api.js";

export default {
  data() {
    return {
      name: "Test",
      email: "test",
      account_holder_name:"",
	    account_number:"",
	    branch_name:"",
	    ifsc_code:"",
      pencilIcon: PencilIcon,
      isDisabled:true,
    };
  },
  methods:{
      useSetPopupOpen(val){
          this.setPopupOpen(val);
      },
      async useGetUserDetails() {
          console.log("testing1234")

      try {
        const user = await getUserDetails();
        if (user.success) {
          this.name = user.name;
          this.email = user.email;
        }
      } catch (err) {
        console.log(err);
      }
    },
      async useGetOwnerAccountDetails() {
          console.log("testing1234")

      try {
        const ownerAcc = await getOwnerAccountDetails();
        console.log(ownerAcc);
        if (ownerAcc.success) {

          console.log("setting oA data");
          this.account_holder_name = ownerAcc.details.account_holder_name;
          this.account_number = ownerAcc.details.account_number;
          this.branch_name = ownerAcc.details.branch_name;
          this.ifsc_code = ownerAcc.details.ifsc_code;
          this.isDisabled = true;
        }
        else if(!ownerAcc.is_payment_added){
          this.isDisabled = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async submitDetails(){
      const body = {
        account_holder_name:this.account_holder_name,
        account_number:this.account_number,
        branch_name:this.branch_name,
        ifsc_code:this.ifsc_code
      }
      const res = await postOwnerAccountDetails(body);
      this.isDisabled = true;
    }
  },
  mounted() {
    console.log("usdtcmng")
    this.useGetUserDetails();
    this.useGetOwnerAccountDetails();
  },
  props: ["currentAccountsTab", "setPopupOpen"],
};
</script>
<style lang="scss" scoped>
.custom-image-container {
  position: relative;
  img {
    height: 7rem;
    width: 7rem;
    border-radius: 5px;
    object-fit: cover;
  }
}
.custom-document-not-submitted {
  width: 100%;
  min-height: 4rem;
  border-radius: 5px;
  background-color: #ffead5;
}
</style>
