<template>
  <div class="h-100 w-100">
    <div class="d-flex flex-column">
      <div
        class="custom-element   p-1 rounded d-flex justify-content-start align-items-center mt-1"
        @click="changeActiveState($event, overview)"
      >
        <div
          class="d-flex justify-centent-center align-items-center rounded custom-pointer"
        >
          <img :src="Home2" alt="" class="inactive" />
          <img :src="Home" alt="" />
          <div class="custom-font font-regular font-weight-600">Overview</div>
        </div>
      </div>
      <div
        class="custom-element active  p-1 rounded d-flex justify-content-start flex-column align-items-start mt-1"
        @click="changeActiveState($event, listings)"
      >
        <div
          class="d-flex justify-centent-center align-items-center rounded custom-pointer"
        >
          <div class="custom-circle "></div>
          <div class="custom-font font-regular font-weight-600 ">Listings</div>
        </div>
      </div>
      <!-- Listings under menu starts here -->
      <div
        class="custom-listing-dropdown w-100 d-flex flex-column justify-content-center align-items-start p-1"
      >
        <div
          class="custom-select-listing font-small font-weight-600 active mx-2 p-1 px-2"
          @click="changeActiveStateOfListing($event, 1)"
        >
          All
        </div>
        <div
          class="custom-select-listing font-small font-weight-600 mx-2 p-1 px-2"
          @click="changeActiveStateOfListing($event,2)"
        >
          Inactive Listing
        </div>
        <div
          class="custom-select-listing font-small font-weight-600 mx-2 p-1 px-2"
          @click="changeActiveStateOfListing($event,3)"
        >
          Active Listing
        </div>
      </div>
      <!-- Listings under menu ends here -->
      <div
        class="custom-element p-1 rounded d-flex justify-content-start align-items-center mt-1"
        @click="changeActiveState($event, reservations)"
      >
        <div
          class="d-flex justify-centent-center align-items-center rounded custom-pointer"
        >
          <img :src="Email2" alt="" class="inactive" />
          <img :src="Email" alt="" />
          <div class="custom-font font-regular font-weight-600">
            Reservations
          </div>
        </div>
      </div>
      <!-- Reservations under menu Starts here -->
      <div
        class="custom-reservation-dropdown display-none w-100 d-flex flex-column justify-content-center align-items-start p-1"
      >
        <div
          class="custom-select-reservation font-small font-weight-600 active mx-2 p-1 px-2"
          @click="changeActiveStateOfReservation($event, 1)"
        >
          All
        </div>
        <div
          class="custom-select-reservation font-small font-weight-600 mx-2 p-1 px-2"
          @click="changeActiveStateOfReservation($event, 2)"
        >
          Currently Hosting
        </div>
        <div
          class="custom-select-reservation font-small font-weight-600 mx-2 p-1 px-2"
          @click="changeActiveStateOfReservation($event, 3)"
        >
          Upcoming
        </div>
        <div
          class="custom-select-reservation font-small font-weight-600 mx-2 p-1 px-2"
          @click="changeActiveStateOfReservation($event, 4)"
        >
          Completed
        </div>
        <div
          class="custom-select-reservation font-small font-weight-600 mx-2 p-1 px-2"
          @click="changeActiveStateOfReservation($event, 5)"
        >
          Cancelled
        </div>
      </div>
      <!-- Reservations under menu ends here -->
      <div
        class="custom-element p-1 rounded d-flex justify-content-start align-items-center mt-1"
        @click="changeActiveState($event, calendar)"
      >
        <div
          class="d-flex justify-centent-center align-items-center rounded custom-pointer"
        >
          <img :src="Calendar2" alt="" class="inactive" />
          <img :src="Calendar" alt="" />
          <div class="custom-fontfont-regular font-weight-600">Calendar</div>
        </div>
      </div>
      <div
        class="custom-element p-1 rounded d-flex justify-content-start align-items-center mt-1"
        @click="changeActiveState($event, account)"
      >
        <div
          class="d-flex justify-centent-center align-items-center rounded custom-pointer"
        >
          <img :src="Tick2" alt="" class="inactive" />
          <img :src="Tick" alt="" />
          <div class="custom-fontfont-regular font-weight-600">Account</div>
        </div>
      </div>
      <!-- Listings under menu starts here -->
      <div
        class="custom-accounts-dropdown display-none w-100 d-flex flex-column justify-content-center align-items-start p-1"
      >
        <div
          class="custom-select-accounts font-small font-weight-600 active mx-2 p-1 px-2"
          @click="changeActiveStateOfAccounts($event, 1)"
        >
          User Details
        </div>
        <div
          class="custom-select-accounts font-small font-weight-600 mx-2 p-1 px-2"
          @click="changeActiveStateOfAccounts($event, 2)"
        >
          Payment Details
        </div>
        <div
          class="custom-select-accounts font-small font-weight-600 mx-2 p-1 px-2"
          @click="changeActiveStateOfAccounts($event, 3)"
        >
          Documents 
        </div>
      </div>
      <!-- Listings under menu ends here -->
      <div
        class="custom-element p-1 rounded d-flex justify-content-start align-items-center mt-1"
        @click="changeActiveState($event, financials)"
      >
        <div
          class="d-flex justify-centent-center align-items-center rounded custom-pointer"
        >
          <img :src="File2" alt="" class="inactive" />
          <img :src="File" alt="" />
          <div class="custom-font font-regular font-weight-600">Financials</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Home from "@/assets/icons/home.png";
import Home2 from "@/assets/icons/home_2.png";
import Email from "@/assets/icons/email_2.png";
import Email2 from "@/assets/icons/email_3.png";
import Message from "@/assets/icons/message.png";
import Message2 from "@/assets/icons/message2.png";
import Tick from "@/assets/icons/tick.png";
import Tick2 from "@/assets/icons/tick_2.png";
import Calendar from "@/assets/icons/calendar.png";
import Calendar2 from "@/assets/icons/calendar_2.png";
import File from "@/assets/icons/files.png";
import File2 from "@/assets/icons/files2.png";
//import useCalendar from "@/views/newcomponents/DashboardItems/Calendar/useCalendar.js";
export default {

  // setup(){
  //   const {
      
  //     changeToNextMonth,
  //   } = useCalendar();
    

  //   return {
  //     changeToNextMonth
  //   }
  // },




  data() {
    return {
      Home,
      Home2,
      Email,
      Email2,
      Message,
      Message2,
      Tick,
      Tick2,
      File,
      File2,
      Calendar,
      Calendar2,
      overview: 1,
      listings: 2,
      reservations: 3,
      inbox: 4,
      calendar: 5,
      account: 6,
      financials: 7,
      listUnder: true,
      reserveUnder: false,
      accountUnder:false,
    };
  },
  methods: {
    changeActiveState(event, value) {
      const elements = document.querySelectorAll(".custom-element");
      let i = 0;
      console.log(elements.length);
      for (i = 0; i < elements.length; i++) {
        elements[i].classList.remove("active");
      }
      event.target.classList.add("active");
      console.log(event.target.classList);

      this.setCurrentTab(value);
      this.listUnder = false;
      this.reserveUnder = false;
      this.accountUnder = false;
      document
        .querySelector(".custom-listing-dropdown")
        .classList.add("display-none");
      document
        .querySelector(".custom-reservation-dropdown")
        .classList.add("display-none");
      document
        .querySelector(".custom-accounts-dropdown")
        .classList.add("display-none");
      
      // if(value == this.calendar){
      //   this.changeToNextMonth();
      // }
      
      if (value == this.listings) {
        document
          .querySelector(".custom-listing-dropdown")
          .classList.remove("display-none");
        this.listUnder = true;
      } else if (value == this.reservations) {
        document
          .querySelector(".custom-reservation-dropdown")
          .classList.remove("display-none");
        this.reserveUnder = true;
      }
       else if (value == this.account) {
        document
          .querySelector(".custom-accounts-dropdown")
          .classList.remove("display-none");
        this.accountUnder = true;
      }
    },
    changeActiveStateOfListing(event, val) {
      const elements = document.querySelectorAll(".custom-select-listing");
      let i = 0;
      //console.log(elements.length);
      for (i = 0; i < elements.length; i++) {
        elements[i].classList.remove("active");
      }
      event.target.classList.add("active");
      //console.log(event.target.classList);
      this.setListingTab(val);
    },

    changeActiveStateOfReservation(event, val) {
      const elements = document.querySelectorAll(".custom-select-reservation");
      let i = 0;
      //console.log(elements.length);
      for (i = 0; i < elements.length; i++) {
        elements[i].classList.remove("active");
      }
      event.target.classList.add("active");
      //console.log(event.target.classList);
      this.setReservationTab(val);
    },
    changeActiveStateOfAccounts(event, val) {
      const elements = document.querySelectorAll(".custom-select-accounts");
      let i = 0;
      //console.log(elements.length);
      for (i = 0; i < elements.length; i++) {
        elements[i].classList.remove("active");
      }
      event.target.classList.add("active");
      //console.log(event.target.classList);
      this.setAccountsTab(val);
    },
  },

  props: ["setCurrentTab", "setReservationTab", "setListingTab", "setAccountsTab"],
};
</script>
<style lang="scss" scoped>
.custom-element {
  //   border: 1px solid rgb(13, 110, 253);
  cursor: pointer;
  .custom-font {
    pointer-events: none;
  }
  .custom-circle {
    height: 1rem;
    width: 1rem;
    border: 2px solid rgba(17, 17, 17, 0.6);
    border-radius: 10rem;
    margin-right: 10px;
    pointer-events: none;
  }
  img {
    height: 1.3rem;
    width: auto;
    margin-right: 10px;
    pointer-events: none;
  }
  .inactive {
    display: none;
    pointer-events: none;
  }
  &.active {
    background-color: rgba(115, 103, 240, 0.2);
    color: rgb(115, 103, 240);
    .custom-circle {
      height: 1rem;
      width: 1rem;
      border: 2px solid #6610f2;
      border-radius: 10rem;
      margin-right: 10px;
      pointer-events: none;
    }
    img {
      display: none;
      pointer-events: none;
    }
    .inactive {
      display: block;
      pointer-events: none;
    }
  }
}
.custom-pointer {
  pointer-events: none;
}
.custom-select-listing {
  cursor: pointer;
  border-left: 1px solid rgba(1, 1, 1, 0.1);
  &.active {
    color: rgb(13, 110, 253);
    border-left: 1px solid rgb(13, 110, 253);
  }
}
.custom-select-reservation {
  cursor: pointer;
  border-left: 1px solid rgba(1, 1, 1, 0.1);
  &.active {
    color: rgb(13, 110, 253);
    border-left: 1px solid rgb(13, 110, 253);
  }
}
.custom-select-accounts {
  cursor: pointer;
  border-left: 1px solid rgba(1, 1, 1, 0.1);
  &.active {
    color: rgb(13, 110, 253);
    border-left: 1px solid rgb(13, 110, 253);
  }
}
.display-none {
  display: none !important;
}
</style>
