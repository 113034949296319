<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <CalendarSidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
          :setCalendarProperty="setCalendarProperty"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              v-if="calendarReveal"
              ref="refCalendar"
              :options="calendarOpt"
              class="full-calendar"
            />

            <!-- <div
              v-show="!calendarReveal"
              class="btn btn-primary"
              @click="setCalendarReveal"
              id="checkButton"
            >
              Reveal Calendar
            </div> -->
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <!-- <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      /> -->
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import store from "@/store";
import { onMounted, onUnmounted } from "@vue/composition-api";
import calendarStoreModule from "./calendarStoreModule";
import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";
import useCalendar from "./useCalendar";
import { mapGetters } from "vuex";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "./event-utils";
import { getCalenderApi } from "@/utils/api.js";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },

  watch: {
    calendarOptions: function() {
      console.log("Calendar Options!", this.calendarOptions);
    },
  },



  data() {
    return {
      calendarReveal: false,
      events: INITIAL_EVENTS,
      calendarOpt: null,
      propertyId : '',

    };
  },

  methods: {
    setCalendarReveal() {
      this.calendarReveal = !this.calendarReveal;
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    async useGetCalenderApi(propertyId) {
      this.calendarReveal = false;
      const data = await getCalenderApi(propertyId);
      var calendarItems;
      if (data.items) {
        calendarItems = data.items.map((item) => {
          return {
            id: item.id,
            url: item.url,
            title: item.title,
            start: this.setDate(item.start),
            end: this.setDate(item.end),
            allDay: item.allDay,
            guest: item.guest,
            extendedProps: {
              calendar: "Business",
            },
            property_id: item.property_id,
          };
        });
      } else {
        calendarItems = [
        ];

        console.log(calendarItems, "here");
      }

      this.calendarOpt = {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        events: calendarItems, // alternatively, use the `events` setting to fetch from a feed
        // events: this.events,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        // select: this.handleDateSelect,
        // eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      };

      console.log("#@##", calendarItems);
      // this.$refs.refCalendar.$emit('refetch-events');

      setTimeout(() => {
        this.calendarReveal = true;
      }, 500);
    },
    setDate(date) {
      console.log("setDate", date);
      const dateArr = date.split("/");
      var newDate = [];
      newDate.push(dateArr[2]);
      newDate.push(dateArr[1]);
      newDate.push(dateArr[0]);
      return newDate.join("-");
    },

    setCalendarProperty(id){
      this.useGetCalenderApi(id);
    }


  },

  mounted() {
      this.useGetCalenderApi();
    },






  props:["openCalendar"],
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
